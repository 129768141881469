export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button-secondary',
  LOAD_MORE_BUTTON_CLASS: 'button button--primary',

  FACET_BUTTON_CLASS: '',
  FACET_INPUT_CLASS: 'form-input',
  MAX_PRICE: 'Max',
  MIN_PRICE: 'Min',

  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'btn-search search-submit',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',
};
