
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('article', { 'className': 'card' }, _createElement('figure', { 'className': 'card-figure' }, _createElement('a', {
                    'href': this.getVehicleSearchUrl(this.custom_url),
                    'className': 'card-figure__link'
                }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                    'className': 'card-image lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.images_image_thumbnail1),
                    'data-src': this.images_image_thumbnail1,
                    'data-sizes': 'auto',
                    'sizes': '150px',
                    'alt': this.removeHTML(this.name),
                    'onError': this.onImageError
                }))), _createElement('figcaption', { 'className': 'card-figcaption' }, _createElement('div', { 'className': 'card-figcaption-body' }, _createElement('button', {
                    'type': 'button',
                    'className': 'button button--small card-figcaption-button quickview',
                    'data-event-type': 'product-click',
                    'data-product-id': this.id,
                    'tabIndex': '0'
                }, '\n          Quick view\n        '), this.has_options && window.Convermax.isUserLogged ? _createElement('a', {
                    'href': this.getVehicleSearchUrl(this.custom_url),
                    'data-event-type': 'product-click',
                    'className': 'button button--small card-figcaption-button',
                    'data-product-id': this.id,
                    'key': '751'
                }, '\n          More Info\n        ') : null, !this.has_options && window.Convermax.isUserLogged ? _createElement('a', {
                    'href': '/cart.php?action=add&product_id=' + this.id + '&qty=1',
                    'data-event-type': 'product-click',
                    'data-button-type': 'add-cart',
                    'className': 'button button--small card-figcaption-button add-to-cart',
                    'key': '1025'
                }, '\n          Add to Cart\n        ') : null))), _createElement('div', { 'className': 'card-body' }, _createElement('p', mergeProps({
                    'className': 'card-text',
                    'data-test-info-type': 'brandName'
                }, { dangerouslySetInnerHTML: { __html: this.brand_name } })), _createElement('h3', { 'className': 'card-title' }, _createElement('a', Object.assign({}, { 'href': this.getVehicleSearchUrl(this.custom_url) }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', {
                    'className': 'card-text',
                    'data-test-info-type': 'price'
                }, !window.Convermax.isUserLogged ? [_createElement('span', { 'key': '17321' }, 'Login for pricing')] : null, window.Convermax.isUserLogged ? [
                    this.on_sale ? _createElement('div', {
                        'className': 'price-section price-section--withoutTax',
                        'key': '18491'
                    }, _createElement('span', {
                        'data-product-rrp-price-without-tax': true,
                        'className': 'price price--rrp'
                    }, '\n            ', this.formatPrice(this.regular_price), '\n          ')) : null,
                    _createElement('div', {
                        'className': 'price-section price-section--withoutTax',
                        'key': '18493'
                    }, _createElement('span', {
                        'data-product-price-without-tax': true,
                        'className': 'price price--withoutTax'
                    }, '\n            ', this.formatPrice(this.price), '\n          '))
                ] : null)));
            }, { count: undefined })];
    }
    return _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-items__container cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]);
}
        export const componentNames = []