import './cart.js';

const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel', 'BodyType', 'BedLength', 'EngineLiter'];
const ignoreFields = ['Universal'];

globalThis.Convermax.isUserLogged = !window.document.querySelector('.header-login');

const fieldsForRelated = ['brand_name'];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

export default {
  platform: 'bigcommerce',
  requestTimeout: 30000,
  SearchRequestDefaults: {
    pageSize: 16,
    extra: {
      'marcor-session-token': localStorage.getItem('marcor-session-token'),
    },
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [{ pathname: '/brands/', field: 'brand_name' }],
    doNotSaveSelectedVehicle: true,
    isVehicleSelectionIsolated: true,
  },
  facets: {
    rangedFacet: [{ fields: ['price'], name: 'priceFacet' }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
      visibleIf: () => window.Convermax.isUserLogged,
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: '#cm_brand-page',
      template: 'SearchPage',
      visibleIf: () => window.Convermax.isUserLogged,
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
        'mpn': 'SKU: A to Z',
      },
    },
    { name: 'SearchResult' },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchBox',
      location: {
        selector: '#cm_search-box_desktop',
        class: 'cm_mobile-hide',
      },
      template: 'SearchBox',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm_ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      fields: baseFitmentFields,
      columnBreakpoint: 700,
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        selector: '#cm_search-box_mobile',
        class: 'cm_desktop-hide',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertAfter: '.productView .productView-info' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: extraFitmentFields,
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: '.productView-description .tabs-contents',
        id: 'tab-fitment',
        class: 'tab-content',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        insertAfter: '.productView-description .tabs .tab:first-child',
        wrapper: 'li',
        class: 'tab',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'RelatedItems',
      location: '#tab-related',
      template: 'product/relatedItems',
      count: 6,
      getSelection,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brand-list',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
      visibleIf: () => window.Convermax.isUserLogged,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
