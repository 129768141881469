//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-932:_816,_128,_5452,_7872,_7496,_2332,_5440,_780;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-932')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-932', "_816,_128,_5452,_7872,_7496,_2332,_5440,_780");
        }
      }catch (ex) {
        console.error(ex);
      }