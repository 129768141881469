const CART_API_URL = 'https://ldz7ppt1x8.execute-api.us-east-1.amazonaws.com/prod/cart';

globalThis.Convermax.handleQuantityButton = (item, action) => {
  const quantityInput = window.document.querySelector(`[data-quantity-change="${item.id}"] input`);

  if (quantityInput) {
    let quantity = parseInt(quantityInput.value);

    if (action === 'minus' && quantity > 1) {
      quantity -= 1;
    } else if (action === 'plus') {
      quantity += 1;
    }

    quantityInput.value = quantity;

    const productCard = window.document.querySelector(`.card[data-product-id="${item.id}"]`);

    const minusButton = productCard.querySelector('.button--minus');
    minusButton?.classList.toggle('disabled', quantity <= 1);

    const requireOptions =
      item.has_modifiers && item.inventory_level !== 9999 && quantity > item.inventory_level;

    const alertBox = productCard.querySelector('.cm_alertBox');
    alertBox?.classList.toggle('cm_hide', !requireOptions);

    const addToCartButton = productCard.querySelector('.add-to-cart');
    if (addToCartButton) {
      addToCartButton.dataset.requireOptions = requireOptions;
    }
  }
};

globalThis.Convermax.addToCart = (event, item) => {
  event.preventDefault();

  const optionModal = window.document.querySelector(`#modal-product-options`);
  const modalOverlay = optionModal.querySelector('.cm_overlay');
  const errorBlock = event.target.parentNode.querySelector('.cm_add-to-cart-error');
  if (errorBlock) {
    errorBlock.classList.add('cm_hide');
  }

  const isModalOpened = !!optionModal?.classList.contains('cm_product-variants-modal-open');

  const requireOptions = event.target.dataset.requireOptions === 'true';

  if (requireOptions) {
    if (!isModalOpened) {
      openAddToCartModal(item.id, item.regular_order, item.special_order);
      return;
    }

    if (modalOverlay) {
      modalOverlay.style.display = 'block';
    }
  }

  const onError = () => {
    if (errorBlock) {
      errorBlock.classList.remove('cm_hide');
    }

    event.target.removeAttribute('disabled');
    event.target.textContent = 'Add to cart';

    if (isModalOpened) {
      window.Convermax.closeAddToCartModal?.();
      modalOverlay.style.display = 'none';
    }
  };

  event.target.setAttribute('disabled', true);
  event.target.textContent = 'Adding to cart…';

  fetch('/api/storefront/carts', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((res) => res.json())
    .then((res) => {
      const cartId = res.length ? res[0].id : null;
      const partNumber = item.removeHTML(item.sku);
      const quantity = parseInt(
        window.document.querySelector(`[data-quantity-change="${item.id}"] input`).value,
      );

      const product = { ['part_number']: partNumber, quantity };

      if (isModalOpened) {
        const selectedOption = [...optionModal.querySelectorAll('input[type="radio"]')].find(
          (input) => input.checked,
        )?.value;

        if (!selectedOption) {
          return;
        }

        const optionId = item.option_id;
        const optionValueId = selectedOption === 'regular' ? item.regular_id : item.special_id;

        product.options = [{ ['option_id']: optionId, ['option_value_id']: optionValueId }];
      }

      const rqData = { ['cart_id']: cartId, products: [product] };
      fetch(CART_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-marcor-session-token': localStorage.getItem('marcor-session-token'),
        },
        body: JSON.stringify(rqData),
      })
        .then((rs) => rs.json())
        .then((rs) => {
          if (rs.cart_url) {
            window.location.href = rs.cart_url;
          } else {
            onError();
            console.error('Add to cart failed', rs);
          }
        })
        .catch((err) => {
          onError();
          console.error(err);
        });
    })
    .catch((err) => {
      onError();
      console.error(err);
    });
};

function openAddToCartModal(productId, regularOrder, specialOrder) {
  const optionModal = window.document.querySelector(`#modal-product-options`);
  if (!optionModal) {
    return;
  }

  optionModal.dataset.productId = productId;

  const regularOptionContainer = optionModal.querySelector('.cm_product-variants-modal-regular');
  const specialOptionContainer = optionModal.querySelector('.cm_product-variants-modal-special');

  optionModal.classList.add('cm_product-variants-modal-open');

  if (regularOrder) {
    regularOptionContainer.style.display = 'flex';
    regularOptionContainer.querySelector('input').addEventListener('change', onOptionInputChange);
  } else {
    regularOptionContainer.style.display = 'none';
  }

  if (specialOrder) {
    specialOptionContainer.style.display = 'flex';
    specialOptionContainer.querySelector('input').addEventListener('change', onOptionInputChange);
  } else {
    specialOptionContainer.style.display = 'none';
  }
}

globalThis.Convermax.closeAddToCartModal = () => {
  const optionModal = window.document.querySelector(`#modal-product-options`);
  if (!optionModal) {
    return;
  }

  delete optionModal.dataset.productId;

  const regularContainer = optionModal.querySelector('.cm_product-variants-modal-regular');
  const specialContainer = optionModal.querySelector('.cm_product-variants-modal-special');
  const addToCartButton = optionModal.querySelector(`.cm_add-to-cart`);

  specialContainer.querySelector('input').checked = false;
  regularContainer.querySelector('input').checked = false;

  specialContainer.removeEventListener('change', onOptionInputChange);
  regularContainer.removeEventListener('change', onOptionInputChange);

  addToCartButton.setAttribute('disabled', true);

  optionModal.classList.remove('cm_product-variants-modal-open');
};

function onOptionInputChange() {
  const addToCartButton = window.document.querySelector('#modal-product-options .cm_add-to-cart');
  if (!addToCartButton) {
    return;
  }

  addToCartButton.removeAttribute('disabled');
}

globalThis.Convermax.addToCartModal = () => {
  const productId = window.document.querySelector(`#modal-product-options`)?.dataset.productId;
  if (!productId) {
    return;
  }

  const addToCartButton = window.document.querySelector(`.card[data-product-id="${productId}"] .add-to-cart`);
  addToCartButton?.click();
};
